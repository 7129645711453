<script setup lang="ts">
/**
 * Pagination by scroll.
 *
 * Usage:
 *  in template:
 *  <Observer @intersect="intersected" />
 *
 *  in code:
 *  const intersected = () => doScroll();
 */
const emit = defineEmits(["intersect"]);
const observer = new IntersectionObserver(([entry]) => {
  if (entry && entry.isIntersecting) {
    emit("intersect");
  }
});
const trigger = ref("trigger");
onMounted(() => observer.observe(trigger.value));
onUnmounted(() => observer.disconnect());
</script>

<template>
  <div ref="trigger" />
</template>
